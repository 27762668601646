import { RedoOutlined } from '@ant-design/icons';
import { Clipboard } from '@nextgis/utils';
import { Alert, Button, Grid, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { appRoutes } from '../../routes/appRoutes';
import { toolBoxStore } from '../../store/store';
import { serializeErrorDescription } from '../../utils/orderUtils';

import { SupportButton } from './SupportButton';
import { OrderInputsInfo, OrderResults } from './orderSubComponents';

import type { Order } from '../../pages/Orders';

const getReuseParametersUrl = (order: Order) => {
  const toolUrl = `../${appRoutes.toolPage}/${order.parameters.operation_name}`;

  const inputs = Object.entries(order.parameters.inputs);
  const inputsPrefill = `?${inputs
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return key + '=' + encodeURIComponent(value.name);
      } else {
        return key + '=' + encodeURIComponent(value);
      }
    })
    .join('&')}`;

  return toolUrl + inputsPrefill;
};

export const OrderDetails: React.FC<{ order: Order }> = ({ order }) => {
  const { t, i18n } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const handleReuseParameters = () => {
    navigate(getReuseParametersUrl(order));
  };

  const [copyText, setCopyText] = useState(t('orders.copyGUID'));

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '6px',
          marginBottom: '10px',
          fontSize: 16,
        }}
      >
        {order.status === 'SUCCESS' ? (
          <OrderResults order={order} />
        ) : (
          <span style={{ lineHeight: 1.9 }}>{order.status}</span>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '6px',
          }}
        >
          {toolBoxStore.appConfig.deploy === 'cloud' && (
            <Button
              href={`${appRoutes.toolPage}/${order.parameters.operation_name}?docs_open=1`}
              target="_blank"
            >
              {t('orders.docLink')}
            </Button>
          )}

          {order?.parameters?.inputs && (
            <a
              href={getReuseParametersUrl(order)}
              onClick={(e) => e.preventDefault()}
            >
              <Button
                icon={
                  !screens.md && <RedoOutlined style={{ fontSize: '16px' }} />
                }
                onClick={handleReuseParameters}
              >
                {screens.md && t('orders.reuseParameters')}
              </Button>
            </a>
          )}
          <SupportButton order={order} />
        </div>
      </div>

      {order.status === 'FAILED' && (
        <Alert
          type="error"
          message={
            <>
              <span>{t('orders.errorMessagePrefix')}</span>
              <br />
              {order.avral_error
                ? serializeErrorDescription(order.avral_error, i18n.language)
                : t('orders.undefinedError')}
            </>
          }
          description={t('orders.errorMessageLabelNew')}
          showIcon
          style={{ margin: '12px 0px', whiteSpace: 'pre-line' }}
        />
      )}
      {order?.parameters?.inputs && <OrderInputsInfo order={order} />}
      <div
        style={{
          textAlign: 'right',
          height: '8px',
        }}
      >
        <Tooltip title={copyText}>
          <Button
            type="link"
            style={{
              border: 0,
              padding: '0px 6px',
              height: '10px',
              color: 'rgb(158, 158, 158)',
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onBlur={() => {
              setCopyText(t('orders.copyGUID'));
            }}
            onClick={() => {
              Clipboard.copy(order.guid);
              setTimeout(() => {
                setCopyText(t('orders.copyGUIDdone'));
              }, 100);
            }}
          >
            {order.guid}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
