const en = {
  translation: {
    og: {
      main: {
        title: 'NextGIS Toolbox',
        description:
          'NextGIS Toolbox is an ever-growing collection of tools for solving spatial data processing tasks.',
      },

      about: {
        title: 'NextGIS Toolbox',
        description: 'About',
      },
      pricing: {
        title: 'NextGIS Toolbox',
        description: 'Prices',
      },
      tasks: {
        title: 'NextGIS Toolbox',
        description: 'Tasks',
      },
    },
    navigation: {
      title: 'Toolbox',
      main: 'Tools',
      about: 'About',
      pricing: 'Pricing',
      orders: 'My tasks',
      login: 'Sign in',
      profile: 'Profile',
      logout: 'Sign Out',
      admin: 'Admin panel',
    },
    footer: {
      terms: 'Terms of Service',
      conf: 'Privacy Policy',
    },
    main: {
      search: 'Search',
      otherError: 'Something went wrong',
      tags: { reset: 'Reset', favourite: 'Favorites' },
      hideInfo: 'Hide',
      showInfo: 'Show info',
      customToolRequest: `Can't find the tool you need?\nLet us know and we will add it`,
      ai1: 'Get NextGIS Account and start chatting with NextGIS Toolbox expert bot!',
      ai2: 'Our AI bot can help finding a tool you need or send a request for a new tool.',
    },
    info: {
      header: '70+ tools for online geodata processing',
      thesis1: 'Options for vector and raster data',
      thesis2: 'Detailed manual for each tool',
      thesis3: 'Integration with Web GIS',
      thesis4: 'History of your tasks',
      thesis5: 'Prompt access to support',
      thesis6: 'API access',
    },
    toolCard: {
      details: 'More info',
      run: 'Run',
      addToFav: 'Add to favorites',
      removeFromFav: 'Remove from favorites',
      favAddError: 'Could not change status, something went wrong',
      favRemoveError: 'Could not change status, something went wrong',
    },
    orders: {
      title: 'My tasks',
      results: 'Results:',
      orderId: 'Order ID:',
      parameters: 'Launch parameters:',
      docLink: 'Docs',
      reuseParameters: 'Re-run',
      contactSupport: 'Contact support',
      contactSupportOk: 'Send',
      contactSupportCancel: 'Cancel',
      contactSupportLabel: 'Contact customer tech support on task №',
      contactSupportDescription:
        'Technical task details will be sent automatically. We will contact you by your email {{ email }}',
      contactSupportTextArealabel: 'Comments:',
      contactSupportSuccessHeader: 'Message sent',
      contactSupportSuccessBody: 'Soon we will contact you by email!',
      contactSupportError: 'Could not send message',
      errorMessageLabelNew:
        'Please review the documentation or report the issue.',
      errorMessagePrefix: 'Error: ',
      parametersError: 'Could not retrieve input parameters 😥',
      otherError: 'Something went wrong',
      resultError: 'Could not retrieve result info',
      toolNameError: 'Could not retrieve tool name',
      undefinedError: 'Could not get error details.',
      select_by_tool: 'Filter by tool',
      select_by_date: 'By date',
      items_per_page: '/ page',
      filters_reset: 'Reset',
      select_options: {
        placeholder: 'Select status',
        all: 'All',
        FAILED: 'Failed',
        DENIED: 'Denied',
        ACCEPTED: 'Accepted',
        STARTED: 'Started',
        SUCCESS: 'Success',
      },
      inputs: {
        true: 'true',
        false: 'false',
        noInput: 'not defined',
        filePrefix: 'file id: ',
        file: 'file',
        download: 'Download',
        downloadError: 'Could not get file url',
        string: 'string',
        boolean: 'boolean',
        number: 'number',
        float: 'float point number',
        int: 'integer',
        integer: 'integer',
        password: '(password)',
      },
      guid: 'GUID',
      copyGUID: 'Copy order ID',
      copyGUIDdone: 'Copied',
      contactSupportMessage: {
        success: '',
        failed: '',
        stalled: '',
      },
    },
    operation: {
      form: 'Form',
      code: 'Python-code',
      docLink: 'About this tool',
      docs: 'Docs',
      details: 'More info',
      upload: 'Upload',
      uploadSelect: 'Select a file',
      uploadDnDsuggestion: 'or drag and drop here',
      submit: 'Run',
      submitSuccess: 'Tool launched',
      copyTT: 'Copy',
      copiedTT: 'Copied!',
      executeError: 'Error!',
      otherError: 'Something went wrong',
      toolDataError: 'Error while retrieving tool data',
      fileId: 'File ID: ',
      needPremium: 'To run this tool you need ',
      needRegister: 'Please login or sign up to use this tool',
      addToFav: 'Add to favorites',
      removeFromFav: 'Remove from favorites',
      onpremiseNeedLogin: 'Authrorization required',
      ngwAuthError: 'Could not auth to related NGW',
      validation: {
        isRequiredMainStart: 'This is a required field. See ',
        isRequiredLinkText: 'docs',
        isRequiredMainEnd: ' for examples.',
      },
    },
    timeOut: {
      message: 'Time out error',
      description:
        'Waiting for server response for too long. Try to reload page soon.',
    },
    noData: 'No data',
    notFound: {
      notFound: 'Page not found',
      checkURL: 'Check url address or',
      supportLink: 'contact support',
      toMain: 'To main page',
    },
  },
};

export default en;
