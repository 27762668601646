const ru = {
  translation: {
    og: {
      main: {
        title: 'NextGIS Toolbox',
        description:
          'Избавьтесь от необходимости устанавливать программное обеспечение ГИС с коллекцией браузерных инструментов NextGIS Toolbox.',
      },
      about: {
        title: 'NextGIS Toolbox',
        description: 'О сервисе',
      },
      pricing: {
        title: 'NextGIS Toolbox',
        description: 'Цены',
      },
      tasks: {
        title: 'NextGIS Toolbox',
        description: 'Задачи',
      },
    },
    navigation: {
      title: 'Toolbox',
      main: 'Инструменты',
      about: 'О сервисе',
      pricing: 'Цены',
      orders: 'Мои задачи',
      login: 'Войти',
      profile: 'Профиль',
      logout: 'Выйти',
      admin: 'Панель администратора',
    },
    footer: {
      terms: 'Пользовательское соглашение',
      conf: 'Политика конфиденциальности',
    },
    main: {
      search: 'Поиск',
      otherError: 'Что-то пошло не так',
      tags: { reset: 'Сбросить', favourite: 'Избранные' },
      hideInfo: 'Скрыть',
      showInfo: 'Инфо',
      customToolRequest:
        'Не нашли нужный инструмент? Расскажите нам, и мы добавим его',
      ai1: 'Get NextGIS Account and start chatting with NextGIS Toolbox expert bot!',
      ai2: 'Our AI bot can help finding a tool you need or send a request for a new tool.',
    },
    info: {
      header: '70+ инструментов для онлайн работы с геоданными',
      thesis1: 'Работа с векторными и растровыми данными',
      thesis2: 'Подробная инструкция к каждому инструменту',
      thesis3: 'Интеграция с Веб ГИС',
      thesis4: 'Хранение истории запусков инструментов',
      thesis5: 'Оперативный доступ к техподдержке',
      thesis6: 'Инструменты для кадастра и лесной отрасли',
    },
    toolCard: {
      details: 'Подробнее',
      run: 'Запустить',
      addToFav: 'Добавить в избранные',
      removeFromFav: 'Убрать из избранных',
      favAddError: 'Не удалось изменить статутс, что-то пошло не так',
      favRemoveError: 'Не удалось изменить статутс, что-то пошло не так',
    },
    orders: {
      title: 'Мои задачи',
      results: 'Результаты:',
      orderId: 'ID задачи:',
      parameters: 'Параметры запуска:',
      docLink: 'Документация',
      reuseParameters: 'Запустить снова',
      contactSupport: 'Сообщить о проблеме',
      contactSupportOk: 'Отправить',
      contactSupportCancel: 'Отменить',
      contactSupportLabel: 'Обращение в тех. поддержку по поводу задачи №',
      contactSupportDescription:
        'Техническая информация о задаче будет отправлена автоматически, мы ответим на вашу электронную почту {{ email }}',
      contactSupportTextArealabel: 'Комментарии (опционально):',
      contactSupportSuccessHeader: 'Обращение отправлено',
      contactSupportSuccessBody: 'Скоро свяжемся с вами по электронной почте!',
      contactSupportError: 'Не удалось отправить сообщение',
      errorMessageLabelNew:
        'Пожалуйста, ознакомьте с документацией или сообщите о проблеме.',
      errorMessagePrefix: 'Ошибка: ',
      reuseParametersTooltip:
        'Эти параметры будут использованы для предзаполнения формы',
      parametersError: 'Не удалось получить параметры запуска 😥',
      otherError: 'Что-то пошло не так',
      resultError: 'Не удалось получить информацию о результате',
      toolNameError: 'Не удалось получить название инструмента',
      undefinedError: 'Не удалось получить данные об ошибке',
      select_by_tool: 'Фильтр по инструменту',
      select_by_date: 'По дате',
      items_per_page: '/ страница',
      filters_reset: 'Сбросить',
      select_options: {
        placeholder: 'Статус',
        all: 'Все',
        FAILED: 'С ошибкой',
        DENIED: 'Отклоненные',
        ACCEPTED: 'В очереди',
        STARTED: 'В процессе',
        SUCCESS: 'Завершённые',
      },
      inputs: {
        true: 'Да',
        false: 'Нет',
        noInput: 'Не заполнено',
        filePrefix: 'ID файла: ',
        file: 'файл',
        download: 'Скачать',
        downloadError: 'Не удалось получить ссылку на файл',
        string: 'строка',
        boolean: 'логическое',
        number: 'число',
        float: 'с плавающей точкой',
        int: 'целое число',
        integer: 'целое число',
        password: '(пароль)',
      },
      guid: 'guid',
      copyGUID: 'Скопировать ID запуска',
      copyGUIDdone: 'Скопировано',
      contactSupportMessage: {
        success:
          'Видим, что задача {{ guid }} выполнилась успешно, но возникли вопросы по результату? Пожалуйста, напишите, что не так.',
        failed:
          'Видим, что задача {{ guid }} завершилась ошибкой. Пожалуйста, задайте вопросы по использованию инструмента.',
        stalled:
          'Видим, что задача {{ guid }} выполняется дольше ожидаемого. ...',
        default:
          'Видим, что у вас возник вопрос по задаче {{ guid }}. Пожалуйста, уточните, что пошло не так',
      },
    },
    operation: {
      form: 'Форма',
      code: 'Python-код',
      details: 'Подробнее',
      docLink: 'Как использовать инструмент',
      docs: 'Документация',
      upload: 'Загрузить',
      uploadSelect: 'Выберите файл',
      uploadDnDsuggestion: 'или перетащите в эту область',
      submit: 'Запустить',
      submitSuccess: 'Инструмент запущен',
      copyTT: 'Скопировать',
      copiedTT: 'Скопировано!',
      executeError: 'Ошибка!',
      otherError: 'Что-то пошло не так',
      toolDataError: 'Ошибка при получении данных инструмента',
      fileId: 'ID файла: ',
      needPremium:
        'Запуск этого инструмента доступен только пользователям на плане ',
      needRegister:
        'Запуск этого инструмента доступен только зарегистрированным пользователям',
      addToFav: 'Добавить в избранные',
      removeFromFav: 'Убрать из избранных',
      onpremiseNeedLogin: 'Необходимо авторизоваться',
      ngwAuthError: 'Не удалось авторизоваться в связанном НГВ',
      validation: {
        isRequiredMainStart: 'Это обязательное поле. Примеры есть в ',
        isRequiredLinkText: 'документации',
        isRequiredMainEnd: '.',
      },
    },
    timeOut: {
      message: 'Не удалось загрузить',
      description:
        'Слишком долгое ожидание ответа от сервера. Попробуйте перезагрузить страницу через некоторое время',
    },
    noData: 'Пусто',
    notFound: {
      notFound: 'Страница не найдена',
      checkURL: 'Проверьте ссылку или',
      supportLink: 'обратитесь в поддержку',
      toMain: 'На главную',
    },
  },
};

export default ru;
